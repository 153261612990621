import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Tokenomics.css";

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 이 값을 조정하여 요소가 화면에 나타나는 정도를 조절할 수 있습니다. 0에서 1사이의 값으로 설정됩니다.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);
  return (
    <div className="Tokenomics" id={id}>
      <div className="Tokenomics-container">
        <h1 className="Tokenomics-title" ref={rightRef}>
          {t("tokenomics")}
        </h1>
        <div className="Tokenomics-divider" />
        <div className="flex-container" ref={homeRef}>
          <div className="Tokenomics-subcontainer">
            <div className="small-container">
              <h1 className="Tokenomics-subtitle-title">NCLS</h1>
              <img
                className="Tokenomics-small-img"
                src="/images/char1.svg"
                alt="ncls"
              />
            </div>

            <h1 className="Tokenomics-subtitle CA">
              <span className="orange uppercase">{t("inscriptionid")}</span>{" "}
              <br />
              <span className="italic font-28 CA">
                38803a4e92cf87df4b66075ebb73fe468c1cdec84771a2e7956b30230748c58fi0
              </span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("supply")}</span> <br />
              <span className="italic font-28">31,415,926</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("decimal")}</span> <br />
              <span className="italic font-28">18</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("deploytime")}</span> <br />
              <span className="italic font-28">2024. 3. 3. AM 9:45:07</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("starttime")}</span> <br />
              <span className="italic font-28">#63077652</span>
            </h1>
            {/* <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("endtime")}</span> <br />
              <span className="italic font-28">#384420</span>
            </h1> */}
          </div>
          <div className="Tokenomics-subcontainer">
            <div className="small-container">
              <h1 className="Tokenomics-subtitle-title">CFAN</h1>
              <img
                className="Tokenomics-small-img"
                src="/images/char2.svg"
                alt="ncls"
              />
            </div>
            <h1 className="Tokenomics-subtitle CA">
              <span className="orange uppercase">{t("inscriptionid")}</span>{" "}
              <br />
              <span className="italic font-28 CA">
                79d0f6786f4a2a7167596fc9a254564719176fb14409c5b9db9f3d43686e8ee6i0
              </span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("supply")}</span> <br />
              <span className="italic font-28">31,415,926</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("decimal")}</span> <br />
              <span className="italic font-28">18</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("deploytime")}</span> <br />
              <span className="italic font-28">2024. 3. 3. AM 9:57:05</span>
            </h1>
            <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("starttime")}</span> <br />
              <span className="italic font-28">#63081180</span>
            </h1>
            {/* <h1 className="Tokenomics-subtitle">
              <span className="orange uppercase">{t("endtime")}</span> <br />
              <span className="italic font-28">#384419</span>
            </h1> */}
          </div>
        </div>

        <br />
      </div>
    </div>
  );
}
