import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./About.css";

type AboutProps = {
  id: string;
};

export default function About({ id }: AboutProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const leftRef_1 = useRef<HTMLDivElement>(null);
  const leftRef_2 = useRef<HTMLDivElement>(null);
  const leftRef_3 = useRef<HTMLDivElement>(null);
  const leftRef_4 = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const rightRef_1 = useRef<HTMLHeadingElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 이 값을 조정하여 요소가 화면에 나타나는 정도를 조절할 수 있습니다. 0에서 1사이의 값으로 설정됩니다.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }

    if (leftRef_1.current) {
      observer2.observe(leftRef_1.current);
    }

    if (leftRef_2.current) {
      observer.observe(leftRef_2.current);
    }

    if (leftRef_3.current) {
      observer.observe(leftRef_3.current);
    }

    if (leftRef_4.current) {
      observer.observe(leftRef_4.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    if (rightRef_1.current) {
      observer2.observe(rightRef_1.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);
  return (
    <div className="About" id={id}>
      <div className="inner-wrap">
        <span className="inner-details italic">
          {t("brc-20")
            .split("\n")
            .map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < t("brc-20").split("\n").length - 1 && <br />}
              </React.Fragment>
            ))}
        </span>
      </div>
      <div className="custom-shape-divider-top-1709471783">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="About-container">
        <div className="opacity" ref={leftRef}>
          <h1 className="About-title">{t("narrative")}</h1>
          <div className="About-divider" />
          <h1 className="About-content">
            {t("narrative_1")
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t("narrative_1").split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
          </h1>
        </div>
      </div>

      <div className="About-container">
        <div className="opacity" ref={leftRef_1}>
          <div className="About-divider" />
          <h1 className="About-content">
            {t("narrative_2")
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t("narrative_2").split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
          </h1>
        </div>
      </div>

      <div className="About-container">
        <div className="opacity" ref={leftRef_2}>
          <div className="About-divider" />
          <h1 className="About-content">
            {t("narrative_3")
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t("narrative_3").split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
          </h1>
        </div>
      </div>
      <div className="About-container">
        <div className="opacity" ref={leftRef_3}>
          <h1 className="About-title">NCLS</h1>
          <h1 className="About-content">BRC-20</h1>
          <div className="About-divider" />
          <h1 className="About-content">{t("supply")}: 31,415,926</h1>
        </div>
        <div className="About-image" ref={rightRef}>
          <img src="/images/char1.svg" alt="ncls" className="About-img" />
        </div>
      </div>
      <div className="About-container">
        <div className="opacity" ref={leftRef_4}>
          <h1 className="About-title">CFAN</h1>
          <h1 className="About-content">BRC-20</h1>
          <div className="About-divider" />
          <h1 className="About-content">{t("supply")}: 31,415,926</h1>
        </div>
        <div className="About-image" ref={rightRef_1}>
          <img src="/images/char2.svg" alt="cfan" className="About-img" />
        </div>
      </div>
    </div>
  );
}
