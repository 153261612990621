import React, { useState, useEffect } from "react";
import "./Header.css";
import { HashLink } from "react-router-hash-link";
import LanguageSwitcher from "../LangSwitcher/LangSwithcer";
import { useTranslation } from "react-i18next";

// const navItems = [{ name: "HOME", path: "/" }];

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="Header">
      <HashLink smooth to="/" className="Header-navbar-start">
        <img src="/images/logo.svg" alt="logo" className="logo-img" />
        {windowWidth > 600 && (
          <span style={{ color: "rgb(255, 183, 76)" }}>
            The Legend of Pi Core Team
          </span>
        )}

        {/* <div className="Header-navbar-Link">
          {navItems.map((item, index) => (
            <HashLink
              key={index}
              smooth
              to={item.path}
              className="Header-navbar-item"
            >
              {item.name}
            </HashLink>
          ))}
        </div> */}
      </HashLink>

      <div className="Header-navbar-end">
        <LanguageSwitcher />
        <span className="Header-navbar-btn">
          <a href="https://unisat.io/market" target="_blank" rel="noreferrer">
            {t("buy_here")}
          </a>
        </span>
      </div>
    </header>
  );
}
