import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const secondHeadingRef = useRef<HTMLHeadingElement>(null);
  const [ca_NCLS, setCaNCLS] = useState("");
  const [ca_CFAN, setCaCFAN] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup_CFAN, setShowPopupCFAN] = useState(false);
  const { t } = useTranslation();

  const handleCopyToClipboard = (ca: string) => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const handleCopyToClipboard_CFAN = (ca: string) => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopupCFAN(true);
        setTimeout(() => setShowPopupCFAN(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCaNCLS(
      "38803a4e92cf87df4b66075ebb73fe468c1cdec84771a2e7956b30230748c58fi0"
    );
    setCaCFAN(
      "79d0f6786f4a2a7167596fc9a254564719176fb14409c5b9db9f3d43686e8ee6i0"
    );

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (secondHeadingRef.current) {
      observer2.observe(secondHeadingRef.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="Home" id={id}>
      <div style={{ display: "flex" }} className="mobile-column">
        <div ref={homeRef} className="Home-container">
          <h1
            id="second-heading"
            style={{ fontSize: "4.5rem", fontWeight: 500, margin: 0 }}
          >
            {t("greeting")
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line.includes("BRC-20") ? (
                    <span className="orange italic">{line}</span>
                  ) : (
                    line
                  )}
                  {index < t("greeting").split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
          </h1>

          <div className="Home-iconbtns">
            <a href="https://unisat.io/market" target="_blank" rel="noreferrer">
              <img
                src="/images/logo_unisat.svg"
                alt="unisat"
                width={60}
                height={60}
              />
            </a>
            <a href="https://t.me/nclscfan" target="_blank" rel="noreferrer">
              <img
                src="/images/Contact-telegram.svg"
                width={60}
                height={60}
                alt="telegram"
              />
            </a>
            <a
              href="https://twitter.com/NicolasToken"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/images/x-logo.svg"
                alt="twitter"
                width={60}
                height={60}
              />
              <span style={{ fontSize: "20px" }}>for NCLS</span>
            </a>
            <a
              href="https://twitter.com/ChengdiaoToken"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/images/x-logo.svg"
                alt="twitter"
                width={60}
                height={60}
              />
              <span style={{ fontSize: "20px" }}>for CFAN</span>
            </a>
          </div>
          <h1
            className="CA"
            style={{ fontSize: "2rem", fontWeight: 500, margin: "1rem 0" }}
          >
            {t("ca")}
          </h1>
          <div>
            <div
              className="CA"
              style={{
                fontSize: "2rem",
                fontWeight: 500,
                margin: 0,
              }}
            >
              <span style={{ color: "#ffb74c" }}>$NCLS</span>:
              <div style={{ display: "flex" }} className="CA">
                <h1 className="whitespace CA">{ca_NCLS}</h1>
                <button
                  className="copy-btn"
                  onClick={() => handleCopyToClipboard(ca_NCLS)}
                >
                  <ContentCopyIcon style={{ color: "white" }} />
                </button>
                {showPopup && (
                  <div className="popup">
                    <p style={{ margin: "0", fontSize: "16px" }}>Copied</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div
              className="CA"
              style={{
                fontSize: "2rem",
                fontWeight: 500,
                margin: 0,
              }}
            >
              <span style={{ color: "#ffb74c" }}>$CFAN</span>:
              <div style={{ display: "flex" }} className="CA">
                <h1 className="whitespace CA">{ca_CFAN}</h1>
                <button
                  className="copy-btn"
                  onClick={() => handleCopyToClipboard_CFAN(ca_CFAN)}
                >
                  <ContentCopyIcon style={{ color: "white" }} />
                </button>
                {showPopup_CFAN && (
                  <div className="popup">
                    <p style={{ margin: "0", fontSize: "16px" }}>Copied</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Main-images Home-image" ref={secondHeadingRef}>
          <img
            src="/images/char1.svg"
            alt="logo"
            className="Home-img floating-image"
          />
          <img
            src="/images/char2.svg"
            alt="logo"
            className="Home-img-2 floating-image"
          />
        </div>
      </div>
    </div>
  );
}
