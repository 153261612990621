import React from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="Contact ">
      <h1 className="Contact-title uppercase">{t("community")}</h1>
      <div className="Contact-iconbtns">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://twitter.com/NicolasToken"
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/x-logo-red.svg"
              alt="twitter"
              width={60}
              height={60}
            />
            <span style={{ fontSize: "20px" }}>for NCLS</span>
          </a>
          <a
            href="https://twitter.com/ChengdiaoToken"
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/x-logo-red.svg"
              alt="twitter"
              width={60}
              height={60}
            />
            <span style={{ fontSize: "20px" }}>for CFAN</span>
          </a>
          <a href="https://t.me/nclscfan" target="_blank" rel="noreferrer">
            <img src="/images/Contact-telegram.svg" alt="etherscan" />
          </a>
        </div>
      </div>
      <h1 className="Contact-content">{t("meme")}</h1>
    </div>
  );
}
