// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import zhTranslation from "./locales/zh.json";

// i18n 초기화
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
  },
  lng: "en", // 기본 언어 설정
  fallbackLng: "en", // 언어가 지원되지 않을 경우의 기본 언어 설정
  interpolation: {
    escapeValue: false, // React 컴포넌트를 문자열 내에서 사용할 수 있게 해줍니다.
  },
});

export default i18n;
