import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

// const useStyles = makeStyles({
//   outlinedInput: {
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#783a8c !important", // 변경하고자 하는 색상으로 설정
//     },
//     fontFamily: "PixelarRegular !important",
//     fontSize: "1.25rem !important",
//   },
//   menuItem: {
//     fontFamily: "PixelarRegular !important",
//     fontSize: "1.25rem !important",
//   },
// });

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={changeLanguage}
      sx={{
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#783a8c !important", // 변경하고자 하는 색상으로 설정
        },
        fontFamily: "PixelarRegular !important",
        fontSize: "1.25rem",
      }}
    >
      <MenuItem
        value="en"
        sx={{
          fontFamily: "PixelarRegular !important",
          fontSize: "1.25rem !important",
        }}
      >
        English
      </MenuItem>{" "}
      {/* 폰트 스타일 적용 */}
      <MenuItem
        value="zh"
        sx={{
          fontFamily: "PixelarRegular !important",
          fontSize: "1.25rem !important",
        }}
      >
        中文
      </MenuItem>{" "}
      {/* 폰트 스타일 적용 */}
    </Select>
  );
}

export default LanguageSwitcher;
